import moment from "moment";
import { getAuthToken } from "./API/authStorage";

export const apiGenerator = (apiObject, exchangePair = {}, join = null) => {
  const apiObj = { ...apiObject };
  if (Object.keys(exchangePair).length) {
    Object.keys(exchangePair).forEach((el) => {
      apiObj.endpoint = apiObj.endpoint.replace(`:${el}`, exchangePair[el]);
    });
  }

  if (join) {
    apiObj.endpoint = `${apiObj.endpoint}${join}`;
  }
  return apiObj;
};

export const isLogin = () => {
  return getAuthToken() !== undefined && getAuthToken() !== null;
};

export const routeIdGen = (route, idPair = {}) => {
  if (Object.keys(idPair).length) {
    Object.keys(idPair).forEach((el) => {
      route = route.replace(`:${el}`, idPair[el]);
    });
  }
  return route
}



export const getClearObject = (obj = {}) => {
  let newObj = {};
  Object.keys(obj).map((key) => {
    let item = obj[key];
    if (typeof item === "boolean") {
      newObj[key] = item;
    } else if (typeof item === "number") {
      newObj[key] = item;
    } else if (typeof item === "string") {
      if (item) {
        newObj[key] = item;
      }
    } else if (Array.isArray(item)) {
      if (item.length) {
        newObj[key] = item;
      }
    } else if (typeof item === "object") {
      // if object is type of date
      if (item instanceof Date) {
        if (!isNaN(item)) {
          newObj[key] = item;
        }
      } else if (Object?.keys(item || {})?.length) {
        newObj[key] = item;
      }
    }
  });

  return newObj;
};

export function formatAmount(amount) {
  const absAmount = Math.abs(amount);

  if (absAmount >= 1e9) {
    return (amount / 1e9)?.toFixed(2) + " B"; // Billion
  } else if (absAmount >= 1e6) {
    return (amount / 1e6)?.toFixed(2) + " M"; // Million
  } else if (absAmount >= 1e3) {
    return (amount / 1e3)?.toFixed(2) + " K"; // Thousand
  } else {
    return amount.toString(); // Less than a thousand
  }
}

export const columChartColor = () => {
  return {
    "Unpaid bill amount": "#dc2626",
    "Paid bill amount": "#62d8a6",
    "UnPaid": "#dc2626",
    "Paid": "#62d8a6",
  }
}

export const getMaxUnit = (date) => {
  const diff = moment().diff(date, 'seconds');

  const seconds = Math.floor(diff);
  const minutes = Math.floor(diff / (60));
  const hours = Math.floor(diff / (60 * 60));
  const days = Math.floor(diff / (60 * 60 * 24));
  const weeks = Math.floor(diff / (60 * 60 * 24 * 7));
  const months = Math.floor(diff / (60 * 60 * 24 * 30));
  const years = Math.floor(diff / (60 * 60 * 24 * 365));

  if (years > 0) {
    return `${years} year${years > 1 ? 's' : ''}`;
  } else if (months > 0) {
    return `${months} month${months > 1 ? 's' : ''}`;
  } else if (weeks > 0) {
    return `${weeks} week${weeks > 1 ? 's' : ''}`;
  } else if (days > 0) {
    return `${days} day${days > 1 ? 's' : ''}`;
  } else if (hours > 0) {
    return `${hours} hour${hours > 1 ? 's' : ''}`;
  } else if (minutes > 0) {
    return `${minutes} minute${minutes > 1 ? 's' : ''}`;
  } else {
    return `${seconds} second${seconds > 1 ? 's' : ''}`;
  }
}

export function getStartAndEndDate(yearMonths) {
  const [startYear, startMonth] = yearMonths[0].split('-').map(Number);
  const [endYear, endMonth] = yearMonths[yearMonths.length - 1].split('-').map(Number);

  const startDate = new Date(startYear, startMonth - 1, 1);
  const endDate = new Date(endYear, endMonth, 0);

  const formatDate = date => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };

  return {
    startDate: formatDate(startDate),
    endDate: formatDate(endDate)
  };
}